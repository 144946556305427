.Choices-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  font-family: Arial, Helvetica, sans-serif;
}

.Choices-btn {
  color: inherit;
  background-color: transparent;
  font-size: inherit;
  font-family: inherit;
  transition-property: box-shadow;
  transition-duration: 0.2s;
  border-radius: 5px;
  box-sizing: content-box;
  border: solid 1px transparent;
  padding: 0.2em;
  outline: none;
}

.Choices-btn:hover {
  cursor: pointer;
  background-color: #eeeeee4d;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.Choices-btn:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
