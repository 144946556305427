.Toast {
  min-width: 250px;
  margin-left: -125px; /* min-width / 2*/
  background-color: #333;
  font-size: 1rem;
  color: white;
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  position: fixed;
  left: 50%;
  top: calc(100vh);
  animation: fadeout 0.5s;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: top 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.Toast.show {
  top: calc(100vh - 80px);
  transition: top 0.25s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.25s step-end;
}
